export const BASE_URL = "https://shippro-backend.sisl.global/";
// export const BASE_URL = "http://localhost/sms/";

export const API = {
  AUTHORIZATION: "authorization",
  LOGIN: "login",
  DASHBOARD: "dashboard/",
  USERLISTING: "users/",
  ADDUSER: "user/create/",
  UPDATE_USER_STATUS: "Api/update_user_status",
  CUSTOMER_ACCOUNT_LIST: "Api/customer_account",
  GET_CUSTOMER_ACCOUNT_LIST: "Api/customer_account_list",
  CUSTOMER_USER_LIST: "Api/list_customer_user",
  CUSTOMER_ACCOUNT_DETAIL: "Api/customer_account_detail/",
  ADD_CUSTOMER_ACCOUNT: "Api/add_customer_account",
  UPDATE_CUSTOMER_ACCOUNT: "Api/update_customer_account",
  MAJOR_ADDRESS_LIST: "Api/get_address",
  ADD_MAJOR_ADDRESS: "Api/add_major_address",
  ITEM_LIST: "Api/get_item_list",
  ADD_ITEMS: "Api/add_items",
  ITEM_DETAIL: "Api/item_detail",
  USER_DETAIL: "Api/user_detail/",
  SAVE_USER: "Api/edit_user",
  ROLE_LIST: "Api/user_role",
  ADD_SHIPPING_REQUEST: "Api/add_shipping_request",
  UPDATE_SHIPPING_REQUEST: "Api/update_shipping_request",
  SHIPPING_REQUEST_LIST: "Api/shipping_request",
  SHIPPING_REQUEST_DETAIL: "Api/shipping_request_detail",
  ASSIGN_CUSTOMER_ACCOUNT: "Api/assign_customer_user",
  GET_ASSIGN_CUSTOMER_ACCOUNT: "Api/assign_customer_account",
  UPLOAD_DOCS: "Api/upload_docs",
  UPDATE_SHIPPING_STATUS: "Api/update_shipping_status",
  SHIPPING_STATUS_DETAIL: "Api/shipping_status",
  SHIPMENT_TRACKING: "Api/shipment_track",
  GENERATE_REPORT: "export/report/",
  UPDATE_COLLECTION_LOGS: "Api/update_collection_fail_logs",
  GET_COLLECTION_LOGS: "Api/get_collection_fail_logs",
  UPDATE_DELIVERY_LOGS: "Api/update_delivery_fail_logs",
  GET_DELIVERY_LOGS: "Api/get_delivery_fail_logs",
  GET_OMS_SO: "Api/list_all_so",
  OMS_SO_DETAIL: "Api/so_detail",
  SHIPMENT_BY_CUSTOMER_ACCOUNT: "Api/shipment_by_customer_account",
  MAP_SHIPMENT: "Api/map_shipment",
  DELETE_PROPERTY: "Api/delete_property",
};
