import React from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";

import {
  Row,
  Col,
  Button,
  Input,
  Select,
  Divider,
  Spin,
  Space,
  notification,
  Checkbox,
} from "antd";
import { MinusCircleOutlined, PlusOutlined } from "@ant-design/icons";
import Form from "../../Helper/Form";
import { token, sessionData } from "../../Helper/Session";
import { withRouter } from "react-router-dom";

class AddShippingRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      slected_address_user_id: "",
      customer_user: [],
      address: [],
      isToAddrLoad: false,
      isFromAddrLoad: false,
      isBtnLoad: false,
      items: [],
      isLoadItems: false,
      customer_account: [],
    };
  }
  formRef = React.createRef();
  formRef1 = React.createRef();
  form = React.createRef();
  componentDidMount() {
    fetch(`${BASE_URL}${API.USERLISTING}?user_role=3`, {
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            customer_user: json.data,
          });
        }
      });
  }

  onFinish = (values) => {
    this.setState({ isBtnLoad: true });
    values.user_id = sessionData.user_id;
    fetch(`${BASE_URL}${API.ADD_SHIPPING_REQUEST}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isBtnLoad: false });
        if (json.status === true) {
          notification.success({ message: json.success_msg });
          this.props.history.push("/shipping-request-list");
        } else {
          notification.error({ message: json.error_msg });
        }
      });
  };

  getMajorAddress = (value) => {
    fetch(`${BASE_URL}${API.MAJOR_ADDRESS_LIST}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ user_id: value }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            slected_address_user_id: value,
            address: json.data,
          });
        } else {
          this.setState({
            address: [],
          });
        }
      });

    fetch(`${BASE_URL}${API.ITEM_LIST}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ user_id: value }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            items: json.data,
          });
        } else {
          this.setState({
            items: [],
          });
        }
      });

    fetch(`${BASE_URL}${API.GET_CUSTOMER_ACCOUNT_LIST}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ user_id: value }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            customer_account: json.data,
          });
        } else {
          this.setState({
            customer_account: [],
          });
        }
      });
  };

  handleShipToAddress = (value) => {
    this.setState({ isToAddrLoad: true });
    fetch(`${BASE_URL}${API.MAJOR_ADDRESS_LIST}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        user_id: this.state.slected_address_user_id,
        id: value,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isToAddrLoad: false });
        this.formRef.current.setFieldsValue({
          to_company_name: json.data.company_name,
          to_contact_name: json.data.contact_name,
          to_contact_number: json.data.mobile,
          to_email: json.data.email,
          to_mobile: json.data.mobile,
          to_street: json.data.street,
          to_city: json.data.city,
          to_state: json.data.state,
          to_country: json.data.country,
          to_postal: json.data.postal,
        });
      });
  };

  handleItemsChange = (key, value) => {
    this.setState({ isLoadItems: true });
    fetch(`${BASE_URL}${API.ITEM_DETAIL}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        item_id: value,
        user_id: sessionData.user_id,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isLoadItems: false });
        var itm = {
          item_id: value,
          weight: json.data.weight,
          length: json.data.length,
          width: json.data.width,
          height: json.data.height,
          value: json.data.content_value,
          content: json.data.content_description,
        };
        const fields = this.formRef.current.getFieldsValue();
        const { items } = fields;
        Object.assign(items[key], itm);
        this.formRef.current.setFieldsValue({
          items,
        });
      });
  };

  handleShipFromAddress = (value) => {
    this.setState({ isFromAddrLoad: true });
    fetch(`${BASE_URL}${API.MAJOR_ADDRESS_LIST}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        user_id: this.state.slected_address_user_id,
        id: value,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isFromAddrLoad: false });
        this.formRef.current.setFieldsValue({
          from_company_name: json.data.company_name,
          from_contact_name: json.data.contact_name,
          from_contact_number: json.data.mobile,
          from_email: json.data.email,
          from_mobile: json.data.mobile,
          from_street: json.data.street,
          from_city: json.data.city,
          from_state: json.data.state,
          from_country: json.data.country,
          from_postal: json.data.postal,
        });
      });
  };
  render() {
    const {
      customer_user,
      address,
      isToAddrLoad,
      isFromAddrLoad,
      items,
      isBtnLoad,
      customer_account,
    } = this.state;
    const { Option } = Select;
    return (
      <Headers
        bodyContain={
          <>
            <div className="site-layout-background form-design">
              <h4>Add Shipping Request</h4>
              <Divider />
              <Form
                ref={this.formRef}
                name="register"
                layout="vertical"
                onFinish={this.onFinish}
                scrollToFirstError
              >
                <Row>
                  {/* <Col md={12}>
                    <Form.Item
                      name="request_id"
                      label="Shipping Request Id"
                      rules={[
                        {
                          required: true,
                          message: "Please enter shipping request id!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col> */}
                  <Col md={12}>
                    <Form.Item
                      name="servicenow_id"
                      label="Service Now Id"
                      tooltip="Please copy from service now or similar client portal."
                      rules={[
                        {
                          required: true,
                          message: "Please enter service now id!",
                          whitespace: true,
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item
                      name="customer_user"
                      label="Customer user"
                      rules={[
                        {
                          required: true,
                          message: "Please select customer user!",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select customer user"
                        onChange={this.getMajorAddress}
                      >
                        {customer_user.map((opt) => {
                          return (
                            <Option value={opt.id}>
                              {opt.first_name + " " + opt.last_name}
                            </Option>
                          );
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col md={12}>
                    <Form.Item
                      name="customer_account"
                      label="Customer account"
                      rules={[
                        {
                          required: true,
                          message: "Please select customer account!",
                        },
                      ]}
                    >
                      <Select placeholder="Select customer account">
                        {customer_account.map((opt) => {
                          return <Option value={opt.id}>{opt.nickname}</Option>;
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Divider />
                <Row>
                  <Col md={12}>
                    <Form.Item
                      name="ship_to"
                      label="Ship to Address"
                      rules={[
                        {
                          required: true,
                          message: "Please select user address",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select shipping to address"
                        onChange={this.handleShipToAddress}
                      >
                        {address.map((opt) => {
                          return (
                            <Option value={opt.id}>
                              {opt.company_name + " - " + opt.contact_name}
                            </Option>
                          );
                        })}
                        <Option value="0">New Address</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Spin spinning={isToAddrLoad}>
                  <Row>
                    <Col md={6}>
                      <Form.Item
                        name="to_company_name"
                        label="Ship to: Company Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter ship to company name!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="to_contact_name"
                        label="Ship to: Contact Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter ship to contact name!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="to_contact_number"
                        label="Ship to: Contact number"
                        rules={[
                          {
                            required: true,
                            message: "Please enter ship to contact number!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="to_email"
                        label="Ship to: Email"
                        rules={[
                          {
                            required: true,
                            message: "Please enter ship to email!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="to_street"
                        label="Ship to: Street"
                        rules={[
                          {
                            required: true,
                            message: "Please enter ship to street address!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col md={6}>
                      <Form.Item
                        name="to_city"
                        label="Ship to: City"
                        rules={[
                          {
                            required: true,
                            message: "Please enter ship to city",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="to_postal"
                        label="Ship to: Postal"
                        rules={[
                          {
                            required: true,
                            message: "Please enter ship to postal",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col md={6}>
                      <Form.Item
                        name="to_state"
                        label="Ship to: State"
                        rules={[
                          {
                            required: true,
                            message: "Please enter ship to state",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col md={6}>
                      <Form.Item
                        name="to_country"
                        label="Ship to: Country"
                        rules={[
                          {
                            required: true,
                            message: "Please enter ship to Country",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Spin>
                <Divider />
                <Row>
                  <Col md={12}>
                    <Form.Item
                      name="ship_from"
                      label="Ship From Address"
                      rules={[
                        {
                          required: true,
                          message: "Please select ship from address",
                        },
                      ]}
                    >
                      <Select
                        placeholder="Select shipping from address"
                        onChange={this.handleShipFromAddress}
                      >
                        {address.map((opt) => {
                          return (
                            <Option value={opt.id}>
                              {opt.company_name + " - " + opt.contact_name}
                            </Option>
                          );
                        })}
                        <Option value="0">New Address</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                </Row>
                <Spin spinning={isFromAddrLoad}>
                  <Row>
                    <Col md={6}>
                      <Form.Item
                        name="from_company_name"
                        label="Ship from: Company Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter ship from company name!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="from_contact_name"
                        label="Ship from: Contact Name"
                        rules={[
                          {
                            required: true,
                            message: "Please enter ship from contact name!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="from_contact_number"
                        label="Ship from: Contact Number"
                        rules={[
                          {
                            required: true,
                            message: "Please enter ship from contact number!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="from_email"
                        label="Ship from: Email"
                        rules={[
                          {
                            required: true,
                            message: "Please enter ship from email!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="from_street"
                        label="Ship from: Street"
                        rules={[
                          {
                            required: true,
                            message: "Please enter ship from street address!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="from_city"
                        label="Ship from: City"
                        rules={[
                          {
                            required: true,
                            message: "Please enter ship from city",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="from_postal"
                        label="Ship from: Postal"
                        rules={[
                          {
                            required: true,
                            message: "Please enter ship from postal address!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={6}>
                      <Form.Item
                        name="from_state"
                        label="Ship from: State"
                        rules={[
                          {
                            required: true,
                            message: "Please enter ship from state",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>

                    <Col md={6}>
                      <Form.Item
                        name="from_country"
                        label="Ship from: Country"
                        rules={[
                          {
                            required: true,
                            message: "Please enter ship from Country",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>
                </Spin>
                <Divider />

                <Form.List name="items">
                  {(fields, { add, remove }) => (
                    <>
                      {fields.map(({ key, name, ...restField }) => (
                        <Space key={key}>
                          <Row>
                            <Divider orientation="left" plain>
                              Box {key + 1}
                            </Divider>
                            <Col>
                              <Form.Item
                                {...restField}
                                name={[name, "item_id"]}
                                label="Box content"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please select Box content!",
                                  },
                                ]}
                              >
                                <Select
                                  placeholder="Select item type"
                                  onChange={this.handleItemsChange.bind(
                                    this,
                                    key
                                  )}
                                  style={{
                                    width: "200px",
                                  }}
                                >
                                  {items.map((opt) => {
                                    return (
                                      <Option value={opt.id}>{opt.name}</Option>
                                    );
                                  })}
                                  <Option value="customized_box">
                                    Customized box
                                  </Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            {/* <Col>
                              <Form.Item
                                {...restField}
                                name={[name, "item_quantity"]}
                                label="Unit quantity"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter item quantity!",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Unit Quantity"
                                  style={{
                                    width: "150px",
                                  }}
                                />
                              </Form.Item>
                            </Col> */}
                            <Col>
                              <Form.Item
                                {...restField}
                                name={[name, "weight"]}
                                label="Gross Weight(kg)"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter weight in kg!",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Gross Weight(kg)"
                                  style={{
                                    width: "150px",
                                  }}
                                />
                              </Form.Item>
                            </Col>

                            <Col>
                              <Form.Item
                                {...restField}
                                name={[name, "length"]}
                                label="Box dimension Length"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter length!",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Length(cm)"
                                  style={{
                                    width: "150px",
                                  }}
                                />
                              </Form.Item>
                            </Col>

                            <Col>
                              <Form.Item
                                {...restField}
                                name={[name, "width"]}
                                label="Box dimension Width"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter width!",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Width(cm)"
                                  style={{
                                    width: "150px",
                                  }}
                                />
                              </Form.Item>
                            </Col>
                            <Col>
                              <Form.Item
                                {...restField}
                                name={[name, "height"]}
                                label="Box dimension Height"
                                rules={[
                                  {
                                    required: true,
                                    message: "Please enter height!",
                                  },
                                ]}
                              >
                                <Input
                                  placeholder="Height(cm)"
                                  style={{
                                    width: "150px",
                                  }}
                                />
                              </Form.Item>
                            </Col>

                            <Col>
                              <Form.Item
                                {...restField}
                                name={[name, "content"]}
                                label="Contents"
                                rules={[
                                  {
                                    required: false,
                                    message: "Please enter contents!",
                                  },
                                ]}
                              >
                                <Input placeholder="Contents" />
                              </Form.Item>
                            </Col>
                            <Col>
                              <Form.Item
                                {...restField}
                                name={[name, "value"]}
                                label="Contents value"
                                rules={[
                                  {
                                    required: false,
                                    message: "Please enter value!",
                                  },
                                ]}
                              >
                                <Input placeholder="Value of Item(Euro)" />
                              </Form.Item>
                            </Col>
                            <Col>
                              <Form.Item
                                {...restField}
                                name={[name, "is_reverse"]}
                                label="Reverse Shipment Required?"
                                tooltip="Please select Yes if Reverse shipment is Required?"
                                rules={[
                                  {
                                    required: false,
                                    message:
                                      "Please select Yes if Reverse shipment is Required?",
                                  },
                                ]}
                              >
                                <Select
                                  defaultValue="no"
                                  placeholder="Shipment Reverse"
                                  style={{
                                    width: "200px",
                                  }}
                                >
                                  <Option value="yes">Yes</Option>
                                  <Option value="no">No</Option>
                                </Select>
                              </Form.Item>
                            </Col>
                            <Col>
                              <Form.Item>
                                <Button
                                  type="danger"
                                  onClick={() => remove(name)}
                                  size="medium"
                                  style={{ marginTop: "21px" }}
                                  icon={<MinusCircleOutlined />}
                                ></Button>
                              </Form.Item>
                            </Col>
                          </Row>
                          {/* <MinusCircleOutlined onClick={() => remove(name)} /> */}
                          {/* <PlusCircleOutlined onClick={() => add(name)} /> */}
                        </Space>
                      ))}
                      <Col md={24}>
                        <Form.Item>
                          <Button
                            type="dashed-secondary"
                            size="large"
                            onClick={() => add()}
                            block
                            style={{ width: "100%" }}
                            icon={<PlusOutlined />}
                          >
                            Add Items
                          </Button>
                        </Form.Item>
                      </Col>
                    </>
                  )}
                </Form.List>

                <Divider />
                <Row>
                  <Col md={12}>
                    <Form.Item>
                      <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        loading={isBtnLoad}
                      >
                        Create Request
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Form>
            </div>
          </>
        }
      />
    );
  }
}
export default withRouter(AddShippingRequest);
