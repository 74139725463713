import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Dashboard from "../Pages/Dashboard";
import Module from "../Pages/Module/Module";
import Users from "../Pages/users/index";
import AddUser from "../Pages/users/adduser";
import AddCustomerUser from "../Pages/users/add-customer-user";
import Login from "../Pages/Login";
import AddShippingRequest from "../Pages/Shipping/add-request";
import CustomerAccount from "../Pages/CustomerAccount";
import AddCustomerAccount from "../Pages/CustomerAccount/addCustomerAccount";
import CustomerUserList from "../Pages/users/customer-user-list";
import EditCustomerUser from "../Pages/CustomerUser/edit-customer-user";
import ShippingRequestList from "../Pages/Shipping/index";
import AllUsersListing from "../Pages/users/all-users";
import ViewShippingRequest from "../Pages/Shipping/view";
import EditCustomerAccount from "../Pages/CustomerAccount/edit-customer-account";
import EditUsers from "../Pages/users/EditUsers";
import Tracking from "../Pages/Shipping/tracking";
import EditShippingRequest from "../Pages/Shipping/edit-request";
import MyAddress from "../Pages/Shipping/my-address";
import OmsSo from "../Pages/oms-so";
import OmsSoDetail from "../Pages/component/oms-so-detail";

const Routers = () => {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Dashboard />
        </Route>
        <Route path="/module">
          <Module />
        </Route>
        <Route exact path="/all-users">
          <AllUsersListing />
        </Route>
        <Route path="/add-user">
          <AddUser />
        </Route>
        <Route path="/add-customer-user">
          <AddCustomerUser />
        </Route>
        <Route path="/customer-users">
          <CustomerUserList user_role={3} />
        </Route>
        <Route exact path="/sisl-users">
          <Users user_role={4} />
        </Route>
        <Route path="/customer-account">
          <CustomerAccount />
        </Route>
        <Route exact path="/add-customer-account">
          <AddCustomerAccount />
        </Route>
        <Route
          exact
          path="/edit-customer-account/:id?"
          render={(props) => {
            return <EditCustomerAccount id={props.match.params?.id} />;
          }}
        />
        <Route exact path="/login">
          <Login />
        </Route>
        <Route
          exact
          path="/oms-so-detail/:id"
          render={(props) => {
            return <OmsSoDetail id={props.match.params?.id} />;
          }}
        />
        <Route path="/add-shipping-request">
          <AddShippingRequest />
        </Route>
        <Route path="/shipping-request-list">
          <ShippingRequestList />
        </Route>
        <Route path="/edit-customer-user">
          <EditCustomerUser />
        </Route>
        <Route path="/shipping-so">
          <OmsSo />
        </Route>
        <Route path="/user/my-address">
          <MyAddress />
        </Route>
        <Route
          path="/users/edit/:id"
          render={(props) => {
            return <EditUsers id={props.match.params.id} />;
          }}
        />
        <Route
          path="/shipping-request/view/:id"
          render={(props) => {
            return <ViewShippingRequest id={props.match.params?.id} />;
          }}
        />
        <Route
          path="/shipping-request/tracking/:id"
          render={(props) => {
            return <Tracking id={props.match.params?.id} />;
          }}
        />
        <Route
          path="/shipping-request/edit/:id"
          render={(props) => {
            return <EditShippingRequest id={props.match.params?.id} />;
          }}
        />
      </Switch>
    </Router>
  );
};

export default Routers;
