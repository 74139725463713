import React from "react";
import { Layout, Menu, Modal, notification, Button, Row, Col } from "antd";
import { Link } from "react-router-dom";

import {
  DesktopOutlined,
  PieChartOutlined,
  UserOutlined,
  LogoutOutlined,
  UnorderedListOutlined,
  HeatMapOutlined,
  SettingOutlined,
} from "@ant-design/icons";

import { withRouter } from "react-router-dom";
import { API, BASE_URL } from "../../Endpoint";
const { Header, Content, Footer, Sider } = Layout;
const { SubMenu } = Menu;

class Headers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapsed: false,
      openkey: "admin",
      theme: "dark",
      sessionData: [],
      img_collapse: "",
    };
  }

  componentDidMount() {
    var getSession = localStorage.getItem("authenticated");
    const parseSession = JSON.parse(getSession);
    this.setState({
      sessionData: parseSession,
      openProfileModal: false,
      logo: "./image/logo-white.png",
      img_collapse: "",
    });
    // console.log(parseSession);
    if (parseSession != null) {
      if (
        typeof parseSession.token === "undefined" ||
        parseSession.token === "" ||
        parseSession.token === " " ||
        parseSession === "null"
      ) {
        notification.error({
          message: "Your session is expired, Please login again",
        });
        this.props.history.push("/login");
      } else {
        fetch(`${BASE_URL}${API.AUTHORIZATION}`, {
          headers: {
            Accept: "application/form-data",
            "Content-Type": "application/json",
            Authorization: "Bearer " + parseSession.token,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            if (json.status === false) {
              localStorage.clear();
              notification.error({
                message: json.error_msg,
              });
              this.props.history.push("/login");
            }
          });
      }
    } else {
      notification.error({
        message: "Your session is expired, Please login again",
      });
      this.props.history.push("/login");
    }
  }

  onCollapse = (collapsed) => {
    if (collapsed === true) {
      this.setState({
        collapsed,
        logo: "./image/favicon.png",
        img_collapse: "img_collapse",
      });
    } else {
      this.setState({
        collapsed,
        logo: "./image/logo-white.png",
        img_collapse: "",
      });
    }
  };
  logout = () => {
    localStorage.removeItem("authenticated");
    notification.warn({ message: "You are successfully logout." });
    window.location.href = "/login";
  };

  showMyProfile = () => {
    this.setState({ openProfileModal: true });
  };
  render() {
    if (
      window.location.pathname === "/module" ||
      window.location.pathname === "/all-users" ||
      window.location.pathname === "/user-role" ||
      window.location.pathname === "/project" ||
      window.location.pathname === "/category" ||
      window.location.pathname === "/add-user" ||
      window.location.pathname === "/customer-account" ||
      window.location.pathname === "/customer-users" ||
      window.location.pathname === "/sisl-users" ||
      window.location.pathname === "/edit-customer-user/"
    ) {
      var openkey = "admin";
    }
    if (
      window.location.pathname === "/add-shipping-request" ||
      window.location.pathname === "/shipping-request-list"
    ) {
      var openkey = "shipping_request";
    }
    var path = window.location.pathname;
    if (path.match("shipping-request/view")) {
      openkey = "shipping_request";
      var selectedKey = "/shipping-request-list";
    }
    if (path.match("edit-customer-account")) {
      openkey = "admin";
      selectedKey = "/customer-account";
    }
    const {
      collapsed,
      theme,
      sessionData,
      openProfileModal,
      logo,
      img_collapse,
    } = this.state;
    return (
      <Layout style={{ minHeight: "100vh" }}>
        <Sider
          collapsible
          collapsed={collapsed}
          onCollapse={this.onCollapse}
          width={256}
          breakpoint="lg"
        >
          <div className="logo">
            <img src={logo} className={img_collapse} />
          </div>
          <Menu
            theme={theme}
            defaultOpenKeys={[openkey]}
            defaultSelectedKeys={[window.location.pathname, selectedKey]}
            mode="inline"
          >
            <Menu.Item key="1" icon={<PieChartOutlined />}>
              <Link to="/">Dashboard</Link>
            </Menu.Item>
            {sessionData.role_id == 1 && (
              <SubMenu
                key="admin"
                icon={<DesktopOutlined />}
                title="Manage Admin"
              >
                <Menu.Item key="/customer-account">
                  <Link to="/customer-account">Customer Account</Link>
                </Menu.Item>
                <Menu.Item key="/customer-users">
                  <Link to="/customer-users">Customer User</Link>
                </Menu.Item>
                <Menu.Item key="/sisl-users">
                  <Link to="/sisl-users">SISL Users</Link>
                </Menu.Item>
                <Menu.Item key="/all-users">
                  <Link to="/all-users">All Users</Link>
                </Menu.Item>
              </SubMenu>
            )}
            <SubMenu
              key="shipping_request"
              icon={<UnorderedListOutlined />}
              title="Shipping Request"
            >
              <Menu.Item key="/shipping-request-list">
                <Link to="/shipping-request-list">Shipping Request List</Link>
              </Menu.Item>
              <Menu.Item key="/add-shipping-request">
                <Link to="/add-shipping-request">Add Shipping Request</Link>
              </Menu.Item>
              {sessionData.role_id == 1 && (
                <Menu.Item key="/shipping-so">
                  <Link to="/shipping-so">All Shipping SO's</Link>
                </Menu.Item>
              )}
            </SubMenu>
            {sessionData.role_id == 3 && (
              <SubMenu
                key="address"
                icon={<HeatMapOutlined />}
                title="Manage Address"
              >
                <Menu.Item key="/user/my-address">
                  <Link to="/user/my-address">My Address</Link>
                </Menu.Item>
              </SubMenu>
            )}
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{ padding: 0 }}>
            <Menu
              theme="light"
              mode="inline"
              style={{ float: "left", width: "250px", borderRight: "none", height: "inherit" }}
            >
              {/* <Menu.Item key="3" onClick={""} className="item_selected"> */}
                <span className="left_greeting">Hello, {sessionData.name}</span>
              {/* </Menu.Item> */}
            </Menu>
            <Menu theme="light" mode="horizontal" style={{ float: "right" }}>
              <Menu.Item key="1">
                <UserOutlined />{" "}
                <span className="submenu" onClick={this.showMyProfile}>
                  My profile
                </span>
              </Menu.Item>
              <Menu.Item key="2" onClick={this.logout}>
                <LogoutOutlined /> <span className="submenu">Logout</span>
              </Menu.Item>
            </Menu>
          </Header>

          <Content style={{ margin: "10px" }}>
            <div style={{ padding: 10, minHeight: 360 }}>
              {this.props.bodyContain}
            </div>
          </Content>
          <Modal
            title="My Profile"
            style={{ top: 20 }}
            visible={openProfileModal}
            onCancel={() => {
              this.setState({ openProfileModal: false });
            }}
            footer={[
              <Button
                key="ok"
                type="primary"
                onClick={() => {
                  this.setState({ openProfileModal: false });
                }}
              >
                Ok
              </Button>,
            ]}
          >
            <div className="my-profile">
              <Row>
                <Col md={12}>
                  <p>
                    Name:{" "}
                    <b>
                      <span>{sessionData.name}</span>
                    </b>
                  </p>
                </Col>
                <Col md={12}>
                  <p>
                    Role:{" "}
                    <b>
                      <span>{sessionData.user_role}</span>
                    </b>
                  </p>
                </Col>
                <Col md={24}>
                  <p>
                    Email:{" "}
                    <b>
                      <span>{sessionData.email}</span>
                    </b>
                  </p>
                </Col>
              </Row>
            </div>
          </Modal>
          <Footer style={{ textAlign: "center", fontWeight: "600" }}>
            {" "}
            ShipPro by{" "}
            <img src="https://shippro-backend.sisl.global/assets/logo/sisl-logo.png" className="img_footer" />
          </Footer>
        </Layout>
      </Layout>
    );
  }
}
export default withRouter(Headers);
