import React from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import {
  Row,
  Col,
  Button,
  Input,
  Select,
  Divider,
  Spin,
  Space,
  notification,
  Table,
} from "antd";
import {
  MinusCircleTwoTone,
  PlusCircleTwoTone,
  EditOutlined,
} from "@ant-design/icons";
import Form from "../../Helper/Form";
import { token, sessionData } from "../../Helper/Session";
import { withRouter } from "react-router-dom";

class EditShippingRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoadingShippingRequestDetail: true,
      slected_address_user_id: "",
      customer_user: [],
      address: [],
      isToAddrLoad: false,
      isFromAddrLoad: false,
      isBtnLoad: false,
      items: [],
      shipping_request_id: props.id,
    };
  }
  formRef = React.createRef();
  form = React.createRef();
  itemForm = React.createRef();
  componentDidMount() {
    fetch(`${BASE_URL}${API.USERLISTING}?user_role=3`, {
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            customer_user: json.data,
          });
        }
      });
    this.setState({ isLoadingShippingRequest: true });
    fetch(`${BASE_URL}${API.SHIPPING_REQUEST_DETAIL}`, {
      method: "post",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        shipping_request_id: this.state.shipping_request_id,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isLoadingShippingRequestDetail: false });
        if (json.status === true) {
          this.setState({
            request: json.data,
            shipping_id: json.data.id,
            items: json.data.items,
          });
          this.formRef.current.setFieldsValue({
            servicenow_id: this.state.request.servicenow_id,
            customer_user: this.state.request.customer_user,
            to_company_name: this.state.request.to_company_name,
            to_contact_name: this.state.request.to_contact_name,
            to_contact_number: this.state.request.to_contact_number,
            to_email: this.state.request.to_email,
            to_street: this.state.request.to_street,
            to_city: this.state.request.to_city,
            to_postal: this.state.request.to_postal,
            to_state: this.state.request.to_state,
            to_country: this.state.request.to_country,
            from_company_name: this.state.request.from_company_name,
            from_contact_name: this.state.request.from_contact_name,
            from_contact_number: this.state.request.from_contact_number,
            from_email: this.state.request.from_email,
            from_street: this.state.request.from_street,
            from_city: this.state.request.from_city,
            from_postal: this.state.request.from_postal,
            from_state: this.state.request.from_state,
            from_country: this.state.request.from_country,
            ship_to: this.state.request.to_company_name,
            ship_from: this.state.request.from_company_name,
          });
        } else {
          notification.error({ message: json.error_msg, placement: "top" });
        }
      });
  }

  onFinish = (values) => {
    this.setState({ isBtnLoad: true });
    values.user_id = sessionData.user_id;
    values.request_id = this.state.shipping_request_id;
    fetch(`${BASE_URL}${API.UPDATE_SHIPPING_REQUEST}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "applicationm/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(values),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isBtnLoad: false });
        if (json.status === true) {
          notification.success({ message: json.success_msg });
          // this.props.history.push("/shipping-request-list");
        } else {
          notification.error({ message: json.error_msg });
        }
      });
  };

  getMajorAddress = (value) => {
    fetch(`${BASE_URL}${API.MAJOR_ADDRESS_LIST}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ user_id: value }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            slected_address_user_id: value,
            address: json.data,
          });
        } else {
          this.setState({
            address: [],
          });
        }
      });

    fetch(`${BASE_URL}${API.ITEM_LIST}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ user_id: value }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            items: json.data,
          });
        } else {
          this.setState({
            items: [],
          });
        }
      });
  };

  handleShipToAddress = (value) => {
    this.setState({ isToAddrLoad: true });
    fetch(`${BASE_URL}${API.MAJOR_ADDRESS_LIST}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        user_id: this.state.slected_address_user_id,
        id: value,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isToAddrLoad: false });
        this.formRef.current.setFieldsValue({
          to_company_name: json.data.company_name,
          to_contact_name: json.data.contact_name,
          to_contact_number: json.data.mobile,
          to_email: json.data.email,
          to_mobile: json.data.mobile,
          to_street: json.data.street,
          to_city: json.data.city,
          to_state: json.data.state,
          to_country: json.data.country,
          to_postal: json.data.postal,
        });
      });
  };

  handleShipFromAddress = (value) => {
    this.setState({ isFromAddrLoad: true });
    fetch(`${BASE_URL}${API.MAJOR_ADDRESS_LIST}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        user_id: this.state.slected_address_user_id,
        id: value,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isFromAddrLoad: false });
        this.formRef.current.setFieldsValue({
          from_company_name: json.data.company_name,
          from_contact_name: json.data.contact_name,
          from_contact_number: json.data.mobile,
          from_email: json.data.email,
          from_mobile: json.data.mobile,
          from_street: json.data.street,
          from_city: json.data.city,
          from_state: json.data.state,
          from_country: json.data.country,
          from_postal: json.data.postal,
        });
      });
  };
  render() {
    const {
      customer_user,
      address,
      isToAddrLoad,
      isFromAddrLoad,
      items,
      isBtnLoad,
      isLoadingShippingRequestDetail,
    } = this.state;
    const { Option } = Select;
    var data = [];
    items.map((itm, i) => {
      data.push({
        key: i + 1,
        name: itm.name,
        qty: itm.qty,
        weight: itm.weight,
        length: itm.length,
        width: itm.width,
        height: itm.height,
        value: itm.value,
        content: itm.content,
        description: itm.description,
        action: (
          <Button
            type="danger"
            style={{ width: "50px" }}
            icon={<EditOutlined />}
          />
        ),
      });
    });
    const columns = [
      {
        title: "SNo",
        dataIndex: "key",
      },
      {
        title: "Item Name",
        dataIndex: "name",
      },
      {
        title: "Quantity",
        dataIndex: "qty",
      },
      {
        title: "Weight(kg)",
        dataIndex: "weight",
      },
      {
        title: "Length(mm)",
        dataIndex: "length",
      },
      {
        title: "Width(mm)",
        dataIndex: "width",
      },
      {
        title: "Height(mm)",
        dataIndex: "height",
      },
      {
        title: "Action",
        dataIndex: "action",
      },
    ];
    return (
      <Headers
        bodyContain={
          <>
            <div className="site-layout-background form-design">
              <h4>Edit Shipping Request</h4>
              <Divider />
              <Form
                ref={this.formRef}
                name="register"
                layout="vertical"
                onFinish={this.onFinish}
                scrollToFirstError
              >
                <Spin spinning={isLoadingShippingRequestDetail}>
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        name="servicenow_id"
                        label="Service Now Id"
                        tooltip="Please copy from service now or similar client portal."
                        rules={[
                          {
                            required: true,
                            message: "Please enter service now id!",
                            whitespace: true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="customer_user"
                        label="Customer user"
                        rules={[
                          {
                            required: true,
                            message: "Please select customer user!",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select customer user"
                          onChange={this.getMajorAddress}
                        >
                          {customer_user.map((opt) => {
                            return (
                              <Option value={opt.id}>
                                {opt.first_name + " " + opt.last_name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        name="ship_to"
                        label="Ship to Address"
                        rules={[
                          {
                            required: true,
                            message: "Please select user address",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select shipping to address"
                          onChange={this.handleShipToAddress}
                        >
                          {address.map((opt) => {
                            return (
                              <Option value={opt.id}>
                                {opt.company_name + " - " + opt.contact_name}
                              </Option>
                            );
                          })}
                          <Option value="0">New Address</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>

                  <Spin spinning={isToAddrLoad}>
                    <Row>
                      <Col md={6}>
                        <Form.Item
                          name="to_company_name"
                          label="Ship to: Company Name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter ship to company name!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="to_contact_name"
                          label="Ship to: Contact Name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter ship to contact name!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="to_contact_number"
                          label="Ship to: Contact number"
                          rules={[
                            {
                              required: true,
                              message: "Please enter ship to contact number!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="to_email"
                          label="Ship to: Email"
                          rules={[
                            {
                              required: true,
                              message: "Please enter ship to email!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="to_street"
                          label="Ship to: Street"
                          rules={[
                            {
                              required: true,
                              message: "Please enter ship to street address!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col md={6}>
                        <Form.Item
                          name="to_city"
                          label="Ship to: City"
                          rules={[
                            {
                              required: true,
                              message: "Please enter ship to city",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="to_postal"
                          label="Ship to: Postal"
                          rules={[
                            {
                              required: true,
                              message: "Please enter ship to postal",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col md={6}>
                        <Form.Item
                          name="to_state"
                          label="Ship to: State"
                          rules={[
                            {
                              required: true,
                              message: "Please enter ship to state",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col md={6}>
                        <Form.Item
                          name="to_country"
                          label="Ship to: Country"
                          rules={[
                            {
                              required: true,
                              message: "Please enter ship to Country",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Spin>
                  <Divider />
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        name="ship_from"
                        label="Ship From Address"
                        rules={[
                          {
                            required: true,
                            message: "Please select ship from address",
                          },
                        ]}
                      >
                        <Select
                          placeholder="Select shipping from address"
                          onChange={this.handleShipFromAddress}
                        >
                          {address.map((opt) => {
                            return (
                              <Option value={opt.id}>
                                {opt.company_name + " - " + opt.contact_name}
                              </Option>
                            );
                          })}
                          <Option value="0">New Address</Option>
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Spin spinning={isFromAddrLoad}>
                    <Row>
                      <Col md={6}>
                        <Form.Item
                          name="from_company_name"
                          label="Ship from: Company Name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter ship from company name!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="from_contact_name"
                          label="Ship from: Contact Name"
                          rules={[
                            {
                              required: true,
                              message: "Please enter ship from contact name!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="from_contact_number"
                          label="Ship from: Contact Number"
                          rules={[
                            {
                              required: true,
                              message: "Please enter ship from contact number!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="from_email"
                          label="Ship from: Email"
                          rules={[
                            {
                              required: true,
                              message: "Please enter ship from email!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="from_street"
                          label="Ship from: Street"
                          rules={[
                            {
                              required: true,
                              message: "Please enter ship from street address!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="from_city"
                          label="Ship from: City"
                          rules={[
                            {
                              required: true,
                              message: "Please enter ship from city",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="from_postal"
                          label="Ship from: Postal"
                          rules={[
                            {
                              required: true,
                              message: "Please enter ship from postal address!",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col md={6}>
                        <Form.Item
                          name="from_state"
                          label="Ship from: State"
                          rules={[
                            {
                              required: true,
                              message: "Please enter ship from state",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col md={6}>
                        <Form.Item
                          name="from_country"
                          label="Ship from: Country"
                          rules={[
                            {
                              required: true,
                              message: "Please enter ship from Country",
                            },
                          ]}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Spin>
                  <Row>
                    <Col md={12}>
                      <Form.Item>
                        <Button
                          type="success"
                          htmlType="submit"
                          size="medium"
                          loading={isBtnLoad}
                          style={{ width: "110px" }}
                        >
                          Save
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Divider />
                  <Divider orientation="left" plain>
                    Line Items
                  </Divider>
                  <Table
                    expandable={{
                      expandedRowRender: (record) => (
                        <>
                          <p style={{ margin: 1 }}>
                            Content :{" "}
                            <span className="rowExp">{record.content}</span>
                          </p>
                          <p style={{ margin: 1 }}>
                            value :{" "}
                            <span className="rowExp">{record.value}</span>
                          </p>
                          <p style={{ margin: 0 }}>{record.description}</p>
                        </>
                      ),
                      expandIcon: ({ expanded, onExpand, record }) =>
                        expanded ? (
                          <MinusCircleTwoTone
                            onClick={(e) => onExpand(record, e)}
                          />
                        ) : (
                          <PlusCircleTwoTone
                            onClick={(e) => onExpand(record, e)}
                          />
                        ),
                    }}
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                  />
                </Spin>
              </Form>
            </div>
          </>
        }
      />
    );
  }
}
export default withRouter(EditShippingRequest);
