import React from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import { message, Upload } from "antd";
import {
  UploadOutlined,
  MinusCircleTwoTone,
  PlusCircleTwoTone,
} from "@ant-design/icons";
import moment from "moment";

import {
  Row,
  Col,
  Divider,
  notification,
  Table,
  Spin,
  Tag,
  Modal,
  Button,
  Input,
  DatePicker,
  Dropdown,
  Space,
  Menu,
  Badge,
  Card,
  Steps,
  List,
  Avatar,
} from "antd";

import Form from "../../Helper/Form";

import { token, sessionData } from "../../Helper/Session";

const { TextArea } = Input;
// console.log(sessionData);

const { Step } = Steps;
class ViewShippingRequest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shipping_request_id: this.props.id,
      isToAddrLoad: false,
      isFromAddrLoad: false,
      isBtnLoad: false,
      isLoadingShippingRequest: false,
      request: [],
      visibleModal: false,
      items: [],
      isShipmentDelivered: false,
      shipping_id: "",
      status_id: 0, //shipping_status_id
      item_id: 0,
      shipping_status_data: [],
      file: "",
      file_url: "",
      isUpdateFailedStatus: false,
      user_id: sessionData.user_id,
      collection_logs: [],
      isDeliveryFailedStatus: false,
      delivery_logs: [],
    };
  }

  formRefDel = React.createRef();
  formRefLab = React.createRef();
  componentDidMount() {
    // console.log(sessionData);
    this.setState({ isLoadingShippingRequest: true });
    fetch(`${BASE_URL}${API.SHIPPING_REQUEST_DETAIL}`, {
      method: "post",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        shipping_request_id: this.state.shipping_request_id,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isLoadingShippingRequest: false });
        if (json.status === true) {
          this.setState({
            request: json.data,
            request_shipping_status: json.data.shipping_status,
            shipping_id: json.data.id,
            items: json.data.items,
          });
          notification.success({ message: json.success_msg, placement: "top" });
        } else {
          notification.error({ message: json.error_msg, placement: "top" });
        }
      });
  }

  handleUpdateStatus = (item_id, status_id) => {
    // alert(item_id + " " + status_id);
    if (status_id == 2) {
      this.setState({
        status_id: status_id,
        item_id: item_id,
        visibleModal: true,
      });
      this.get_items_status(status_id, item_id);
    } else if (status_id == 6) {
      this.setState({
        item_id: item_id,
        status_id: status_id,
        isShipmentDelivered: true,
      });
      this.get_items_status(status_id, item_id);
    } else if (status_id == 4) {
      this.setState({
        item_id: item_id,
        status_id: status_id,
      });
      fetch(`${BASE_URL}${API.UPDATE_SHIPPING_STATUS}`, {
        method: "POST",
        headers: {
          Accept: "application/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({
          shipping_id: this.state.shipping_id,
          status: status_id,
          item_id: item_id,
        }),
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.status == true) {
            this.setState({
              request_shipping_status: status_id,
            });
            message.success(json.success_msg);
          } else {
            message.error(json.error_msg);
          }
        });
    }
  };

  saveLabelCreation = (value) => {
    fetch(`${BASE_URL}${API.UPDATE_SHIPPING_STATUS}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        shipping_id: this.state.shipping_id,
        label_creation_date: value.creation_date,
        carrier: value.carrier,
        tracking: value.tracking_no,
        pickup_date: value.pickup_date,
        pickup_time: value.pickup_time,
        url: value.tracking_url,
        status: this.state.status_id,
        // label: value.shipping_label["file"]["name"],
        item_id: this.state.item_id,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({ visibleModal: false, request_shipping_status: 1 });
          notification.success({ message: json.success_msg, placement: "top" });
        } else {
          notification.error({ message: json.error_msg, placement: "top" });
        }
      });
  };

  saveDelivery = (value) => {
    fetch(`${BASE_URL}${API.UPDATE_SHIPPING_STATUS}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        shipping_id: this.state.shipping_id,
        delivery_date: value.delivery_date,
        status: this.state.status_id,
        // pod: value.pod["file"]["name"],
        item_id: this.state.item_id,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            isShipmentDelivered: false,
            request_shipping_status: 3,
          });
          notification.success({ message: json.success_msg, placement: "top" });
        } else {
          notification.error({ message: json.error_msg, placement: "top" });
        }
      });
  };

  get_items_status = (status, line_item_id) => {
    this.setState({
      file: "",
      file_url: "",
    });
    fetch(`${BASE_URL}${API.SHIPPING_STATUS_DETAIL}`, {
      method: "post",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        shipping_id: this.state.shipping_request_id,
        line_item_id: line_item_id,
        status: status,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            shipping_status_data: json.data,
          });
          if (status == 1) {
            this.formRefLab.current.setFieldsValue({
              carrier: this.state.shipping_status_data.carrier,
              creation_date: moment(
                this.state.shipping_status_data.label_creation_date
              ),
              tracking_no: this.state.shipping_status_data.tracking,
              tracking_url: this.state.shipping_status_data.url,
              pickup_date: moment(this.state.shipping_status_data.pickup_date),
              pickup_time: this.state.shipping_status_data.pickup_time,
            });
            this.setState({
              file: json.data.label,
              file_url: json.data.label_url,
            });
          } else if (status == 3) {
            this.formRefDel.current.setFieldsValue({
              delivery_date: moment(
                this.state.shipping_status_data.delivery_date
              ),
            });
            this.setState({
              file: json.data.pod,
              file_url: json.data.pod_url,
            });
          }
        }
      });
  };

  handleUpdateFailedStatus = (item_id, status_id) => {
    this.setState({
      item_id: item_id,
      status_id: status_id,
      collection_logs: [],
    });
    if (status_id == 3) {
      this.setState({ isUpdateFailedStatus: true });
      this.getCollectionFailLogs(item_id);
    } else if (status_id == 5) {
      this.setState({ isDeliveryFailedStatus: true });
      this.getDeliveryFailLogs(item_id);
    }
  };

  saveCollectionFailLogs = (value) => {
    fetch(`${BASE_URL}${API.UPDATE_COLLECTION_LOGS}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        status_id: this.state.status_id,
        request_id: this.state.shipping_request_id,
        item_id: this.state.item_id,
        failure_date: value.failure_date,
        failure_time: value.failure_time,
        reschedule_date: value.reschedule_date,
        reschedule_time: value.reschedule_time,
        reason: value.reason,
        user_id: this.state.user_id,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            isShipmentDelivered: false,
            request_shipping_status: 3,
          });
          notification.success({ message: json.success_msg, placement: "top" });
          this.getCollectionFailLogs(this.state.item_id);
        } else {
          notification.error({ message: json.error_msg, placement: "top" });
        }
      });
  };

  saveDeliveryFailLogs = (value) => {
    fetch(`${BASE_URL}${API.UPDATE_DELIVERY_LOGS}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        status_id: this.state.status_id,
        request_id: this.state.shipping_request_id,
        item_id: this.state.item_id,
        failure_date: value.failure_date,
        failure_time: value.failure_time,
        reschedule_date: value.reschedule_date,
        reschedule_time: value.reschedule_time,
        reason: value.reason,
        user_id: this.state.user_id,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            isShipmentDelivered: false,
            request_shipping_status: 5,
          });
          notification.success({ message: json.success_msg, placement: "top" });
          this.getDeliveryFailLogs(this.state.item_id);
        } else {
          notification.error({ message: json.error_msg, placement: "top" });
        }
      });
  };

  getCollectionFailLogs = (item_id) => {
    fetch(`${BASE_URL}${API.GET_COLLECTION_LOGS}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        item_id: item_id,
        user_id: this.state.user_id,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            collection_logs: json.data,
          });
        }
      });
  };

  getDeliveryFailLogs = (item_id) => {
    fetch(`${BASE_URL}${API.GET_DELIVERY_LOGS}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        item_id: item_id,
        user_id: this.state.user_id,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            delivery_logs: json.data,
          });
        }
      });
  };

  render() {
    const {
      request,
      request_shipping_status,
      items,
      isLoadingShippingRequest,
      visibleModal,
      isShipmentDelivered,
      shipping_id,
      item_id,
      isUpdateFailedStatus,
      collection_logs,
      isDeliveryFailedStatus,
      delivery_logs,
    } = this.state;
    const columns = [
      {
        title: "SNo",
        dataIndex: "key",
      },
      {
        title: "Item Type",
        dataIndex: "name",
      },
      // {
      //   title: "Unit Quantity",
      //   dataIndex: "qty",
      // },
      {
        title: "Gross Weight(kg)",
        dataIndex: "weight",
      },
      {
        title: "Length(cm)",
        dataIndex: "length",
      },
      {
        title: "Width(cm)",
        dataIndex: "width",
      },
      {
        title: "Height(cm)",
        dataIndex: "height",
      },
      {
        title: "Action",
        dataIndex: "action",
      },
    ];

    if (sessionData.role_id == 3) {
      columns.splice(6, 1, {
        title: "POD/Tracking URL",
        dataIndex: "download",
      });
    }

    var data = [];
    items.map((itm, i) => {
      data.push({
        key: i + 1,
        name: itm.name == null ? "Customized Box" : itm.name,
        // qty: itm.qty,
        weight: itm.weight,
        length: itm.length,
        width: itm.width,
        height: itm.height,
        value: itm.value,
        content: itm.content,
        description: itm.description,
        url: itm.url,
        status: itm.status,
        pod: itm.pod,
        collection_fail: itm.collection_fail,
        delivery_fail: itm.delivery_fail,
        download: (
          <>
            {itm.status >= 2 && (
              <p style={{ margin: 1 }}>
                <a target="_blank" href={itm.url}>
                  tracking url
                </a>
              </p>
            )}
            {itm.status == 3 && (
              <div style={{ margin: 1 }}>
                <a className="btn btn-success" target="_blank" href={itm.pod}>
                  Download POD
                </a>
              </div>
            )}
          </>
        ),
        action: (
          <>
            {sessionData.role_id == 1 && (
              <Space wrap>
                <Dropdown.Button
                  overlay={
                    <Menu
                      items={[
                        {
                          key: "2",
                          label: (
                            <Button
                              type="primary"
                              className="actionButton"
                              onClick={() => this.handleUpdateStatus(itm.id, 2)}
                            >
                              Shipping label created
                            </Button>
                          ),
                        },
                        {
                          key: "3",
                          label: (
                            <Button
                              type="danger"
                              className="actionButton"
                              onClick={() =>
                                this.handleUpdateFailedStatus(itm.id, 3)
                              }
                            >
                              Collection fail
                            </Button>
                          ),
                        },
                        {
                          key: "4",
                          label: (
                            <Button
                              type="secondary"
                              className="actionButton"
                              onClick={() => this.handleUpdateStatus(itm.id, 4)}
                            >
                              Shipment In-Transit
                            </Button>
                          ),
                        },
                        {
                          key: "5",
                          label: (
                            <Button
                              type="orange"
                              className="actionButton"
                              onClick={() =>
                                this.handleUpdateFailedStatus(itm.id, 5)
                              }
                            >
                              Delivery
                            </Button>
                          ),
                        },
                        {
                          key: "6",
                          label: (
                            <Button
                              type="warning"
                              className="actionButton"
                              onClick={() => this.handleUpdateStatus(itm.id, 6)}
                            >
                              Shipment delivered
                            </Button>
                          ),
                        },
                      ]}
                    />
                  }
                  type="dashed"
                  placement="left"
                >
                  Action
                </Dropdown.Button>
              </Space>
            )}
          </>
        ),
      });
    });
    const fileList = [
      {
        uid: "1",
        name: this.state.file,
        status: this.state.file ? "done" : "error",
        url: this.state.file_url,
      },
    ];
    // console.log(fileList);
    const props = {
      action: `${BASE_URL}${API.UPLOAD_DOCS}`,
      multiple: false,
      maxCount: 1,
      headers: {
        Accept: "application/form-data",
        Authorization: "Bearer " + token,
      },
      fileList: fileList[0].status == "done" ? fileList : [],
      onChange(info) {
        // console.log(info);
        if (info.file.status !== "uploading") {
          console.log(info.file, info.fileList);
        }
        if (info.file.status == "done") {
          message.success(`${info.file.name} file uploaded successfully`);
        } else if (info.file.status == "error") {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
      // onRemove(v) {
      //   console.log(v);
      // },
    };
    return (
      <Headers
        bodyContain={
          <>
            <div className="site-layout-background form-design">
              <h4>Shipping Request Id : {request.request_id} </h4>
              <Steps
                size="small"
                current={parseInt(request_shipping_status) - 1}
                status={
                  parseInt(request_shipping_status) == 3 ||
                  parseInt(request_shipping_status) == 5
                    ? "error"
                    : "process"
                }
              >
                <Step
                  title="Request Placed"
                  description="This mean shipping for this request is placed."
                />
                <Step
                  title="Shipping label created"
                  description="This mean user label uploaded for this shipping request."
                />
                <Step title="Pickup" description="" />
                <Step
                  title="In-Transit"
                  description="Your shipping request is In-Transit."
                />
                <Step title="Delivery" description="" />
                <Step title="Delivered" description="Shipment is delivered." />
              </Steps>

              <br />
              {request_shipping_status == 1 && (
                <Tag color="#002140">Request placed</Tag>
              )}
              {request_shipping_status == 2 && (
                <Tag color="#019b04">Shipping Label created</Tag>
              )}
              {request_shipping_status == 4 && (
                <Tag color="#e2ca10">In-Transit</Tag>
              )}
              {request_shipping_status == 6 && (
                <Tag color="#1b74c6">Delivered</Tag>
              )}
              {request_shipping_status == 3 && (
                <Tag color="#ff7875">Collection Failed</Tag>
              )}
              {request_shipping_status == 5 && (
                <Tag color="#ff7875">Delivery Failed</Tag>
              )}
              <Divider />
              <div className="shipping_detail">
                <Spin spinning={isLoadingShippingRequest}>
                  <Row>
                    {/* <Col md={12}>
                      <h5>Shipping Request Id</h5>
                      <p>{request.request_id}</p>
                    </Col> */}
                    <Col md={12}>
                      <p style={{ fontWeight: "600" }}>Servicenow Id</p>
                      <p>{request.servicenow_id}</p>
                    </Col>
                    <Col md={12}>
                      <p style={{ fontWeight: "600" }}>Customer User</p>
                      <p>{request.first_name + " " + request.last_name}</p>
                    </Col>
                  </Row>
                  <Row>
                    {/* <Col md={12}>
                      <Card className="address">
                        <p className="text-center">Shipping to Address</p>
                        <Row>
                          <Col sm={12}>
                            <p>Company Name</p>
                          </Col>
                          <Col sm={12}>
                            <p>{request.to_company_name}</p>
                          </Col>
                          <Col sm={12}>
                            <p>Contact Name</p>
                          </Col>
                          <Col sm={12}>
                            <p>{request.to_contact_name}</p>
                          </Col>

                          <Col sm={12}>
                            <p>Email</p>
                          </Col>
                          <Col sm={12}>
                            <p>{request.to_email}</p>
                          </Col>
                          <Col sm={12}>
                            <p>Street</p>
                          </Col>
                          <Col sm={12}>
                            <p>{request.to_street}</p>
                          </Col>
                          <Col sm={12}>
                            <p>City</p>
                          </Col>
                          <Col sm={12}>
                            <p>{request.to_city}</p>
                          </Col>
                          <Col sm={12}>
                            <p>State</p>
                          </Col>
                          <Col sm={12}>
                            <p>{request.to_state}</p>
                          </Col>
                          <Col sm={12}>
                            <p>Country</p>
                          </Col>
                          <Col sm={12}>
                            <p>{request.to_country}</p>
                          </Col>
                        </Row>
                      </Card>
                    </Col> */}
                    <Col md={12}>
                      <Badge.Ribbon
                        text={
                          request.is_reverse == "yes"
                            ? "Shipping from Address"
                            : "Shipping to Address"
                        }
                        color={request.is_reverse == "yes" ? "green" : ""}
                      >
                        <Card className="address">
                          <p className="text-center"></p>
                          <Row>
                            <Col sm={24}>
                              <p>
                                {request.to_contact_name +
                                  ", " +
                                  request.to_company_name}
                              </p>
                            </Col>
                            <Col sm={24}>
                              <p>
                                {request.to_street +
                                  ", " +
                                  request.to_city +
                                  ", " +
                                  request.to_state +
                                  ", " +
                                  request.to_country +
                                  " " +
                                  (request.to_postal
                                    ? " - " + request.to_postal
                                    : "")}
                              </p>
                            </Col>
                            <Col sm={24}>
                              <p>
                                {(request.to_contact_number
                                  ? request.to_contact_number + "/ "
                                  : "") +
                                  "" +
                                  request.to_email}
                              </p>
                            </Col>
                          </Row>
                        </Card>
                      </Badge.Ribbon>
                    </Col>
                    {/* <Col md={12}>
                      <Card md={6} className="address">
                        <h4 className="text-center">Shipping from Address</h4>
                        <Row>
                          <Col sm={12}>
                            <p>Company Name</p>
                          </Col>
                          <Col sm={12}>
                            <p>{request.from_company_name}</p>
                          </Col>
                          <Col sm={12}>
                            <p>Contact Name</p>
                          </Col>
                          <Col sm={12}>
                            <p>{request.from_contact_name}</p>
                          </Col>
                          <Col sm={12}>
                            <p>Email</p>
                          </Col>
                          <Col sm={12}>
                            <p>{request.from_email}</p>
                          </Col>
                          <Col sm={12}>
                            <p>Street</p>
                          </Col>
                          <Col sm={12}>
                            <p>{request.from_street}</p>
                          </Col>
                          <Col sm={12}>
                            <p>City</p>
                          </Col>
                          <Col sm={12}>
                            <p>{request.from_city}</p>
                          </Col>
                          <Col sm={12}>
                            <p>State</p>
                          </Col>
                          <Col sm={12}>
                            <p>{request.from_state}</p>
                          </Col>
                          <Col sm={12}>
                            <p>Country</p>
                          </Col>
                          <Col sm={12}>
                            <p>{request.from_country}</p>
                          </Col>
                        </Row>
                      </Card>
                    </Col> */}
                    <Col md={12}>
                      <Badge.Ribbon
                        text={
                          request.is_reverse == "yes"
                            ? "Shipping to Address"
                            : "Shipping from Address"
                        }
                        color={request.is_reverse == "yes" ? "" : "green"}
                      >
                        <Card md={6} className="address">
                          <p className="text-center"></p>
                          <Row>
                            <Col sm={24}>
                              <p>
                                {request.from_contact_name +
                                  ", " +
                                  request.from_company_name}
                              </p>
                            </Col>

                            <Col sm={24}>
                              <p>
                                {request.from_street +
                                  ", " +
                                  request.from_city +
                                  ", " +
                                  request.from_state +
                                  ", " +
                                  request.from_country +
                                  " " +
                                  (request.from_postal
                                    ? " - " + request.from_postal
                                    : "")}
                              </p>
                            </Col>
                            <Col sm={24}>
                              <p>
                                {(request.from_contact_number
                                  ? request.from_contact_number + "/ "
                                  : "") +
                                  "" +
                                  request.from_email}
                              </p>
                            </Col>
                          </Row>
                        </Card>
                      </Badge.Ribbon>
                    </Col>
                  </Row>

                  <div className="line_item"></div>
                  <Table
                    expandable={{
                      expandedRowRender: (record) => (
                        <>
                          <p style={{ margin: 1 }}>
                            Content :{" "}
                            <span className="rowExp">{record.content}</span>
                          </p>
                          <p style={{ margin: 1 }}>
                            value :{" "}
                            <span className="rowExp">{record.value}</span>
                          </p>
                          <p style={{ margin: 0 }}>{record.description}</p>
                          {record.status >= 2 && (
                            <p style={{ margin: 1 }}>
                              Tracking URL :{" "}
                              <a target="_blank" href={record.url}>
                                tracking url
                              </a>
                            </p>
                          )}
                          {record.status == 3 && (
                            <div style={{ margin: 1 }}>
                              POD :{" "}
                              <a
                                className="btn btn-success"
                                target="_blank"
                                href={record.pod}
                              >
                                Download POD
                              </a>
                            </div>
                          )}
                          <Row>
                            {record.collection_fail &&
                              record.collection_fail.attempt != 0 && (
                                <Col md={12}>
                                  <Card>
                                    <List.Item>
                                      <List.Item.Meta
                                        title={
                                          <Tag color="lime">
                                            Attempt{" "}
                                            {record.collection_fail.attempt}
                                          </Tag>
                                        }
                                        description={
                                          <>
                                            <p>
                                              <b>Collection Failed!</b>
                                            </p>
                                            <p>
                                              Failure Date & Time :{" "}
                                              <Tag color="red">
                                                {moment(
                                                  record.collection_fail
                                                    .failure_date +
                                                    " " +
                                                    record.collection_fail
                                                      .failure_time
                                                ).format("DD-MM-YYYY hh:mm a")}
                                              </Tag>
                                            </p>
                                            <p>
                                              Reschedule Date & Time :{" "}
                                              <Tag color="success">
                                                {moment(
                                                  record.collection_fail
                                                    .reschedule_date +
                                                    " " +
                                                    record.collection_fail
                                                      .reschedule_time
                                                ).format("DD-MM-YYYY hh:mm a")}
                                              </Tag>
                                            </p>
                                            <p>
                                              Reason:{" "}
                                              <b>
                                                {record.collection_fail.reason}
                                              </b>
                                            </p>
                                          </>
                                        }
                                      />
                                    </List.Item>
                                  </Card>
                                </Col>
                              )}
                            {record.delivery_fail &&
                              record.delivery_fail.attempt != 0 && (
                                <Col md={12}>
                                  <Card>
                                    <List.Item>
                                      <List.Item.Meta
                                        title={
                                          <Tag color="lime">
                                            Attempt{" "}
                                            {record.delivery_fail.attempt}
                                          </Tag>
                                        }
                                        description={
                                          <>
                                            <p>
                                              <b>Delivery Failed!</b>
                                            </p>
                                            <p>
                                              Failure Date & Time :{" "}
                                              <Tag color="red">
                                                {moment(
                                                  record.delivery_fail
                                                    .failure_date +
                                                    " " +
                                                    record.delivery_fail
                                                      .failure_time
                                                ).format("DD-MM-YYYY hh:mm a")}
                                              </Tag>
                                            </p>
                                            <p>
                                              Reschedule Date & Time :{" "}
                                              <Tag color="success">
                                                {moment(
                                                  record.delivery_fail
                                                    .reschedule_date +
                                                    " " +
                                                    record.delivery_fail
                                                      .reschedule_time
                                                ).format("DD-MM-YYYY hh:mm a")}
                                              </Tag>
                                            </p>
                                            <p>
                                              Reason:{" "}
                                              <b>
                                                {record.delivery_fail.reason}
                                              </b>
                                            </p>
                                          </>
                                        }
                                      />
                                    </List.Item>
                                  </Card>
                                </Col>
                              )}
                          </Row>
                        </>
                      ),
                      expandIcon: ({ expanded, onExpand, record }) =>
                        expanded ? (
                          <MinusCircleTwoTone
                            onClick={(e) => onExpand(record, e)}
                          />
                        ) : (
                          <PlusCircleTwoTone
                            onClick={(e) => onExpand(record, e)}
                          />
                        ),
                    }}
                    dataSource={data}
                    columns={columns}
                    pagination={false}
                  />
                </Spin>
              </div>
            </div>
            {/* Modal start */}
            <Modal
              style={{ top: 20 }}
              visible={visibleModal}
              title="Update Shipping label created"
              onCancel={() => {
                this.setState({ visibleModal: false });
              }}
              destroyOnClose={true}
              footer={[
                <Button
                  type=""
                  onClick={() => {
                    this.setState({ visibleModal: false });
                  }}
                >
                  Cancel
                </Button>,
              ]}
              width={800}
            >
              <div className="form-design">
                <Form
                  layout="vertical"
                  onFinish={this.saveLabelCreation}
                  scrollToFirstError
                  ref={this.formRefLab}
                >
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        name="creation_date"
                        label="Label creation date"
                        rules={[
                          {
                            required: true,
                            message: "Please enter label creation date!",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          format="DD-MM-YYYY"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="carrier"
                        label="Carrier"
                        rules={[
                          {
                            required: true,
                            message: "Please enter carrier!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="tracking_no"
                        label="Tracking"
                        rules={[
                          {
                            required: true,
                            message: "Please enter tracking!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="tracking_url"
                        label="Tracking URL"
                        rules={[
                          {
                            required: true,
                            message: "Please enter tracking url!",
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="pickup_date"
                        label="Pick up date"
                        rules={[
                          {
                            required: true,
                            message: "Please enter pickup date!",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          format="DD-MM-YYYY"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="pickup_time"
                        label="Pickup time"
                        rules={[
                          {
                            required: true,
                            message: "Please enter pickup time!",
                          },
                        ]}
                      >
                        {/* <TimePicker
                          showTime={{ use12Hours: true, format: "HH:mm A" }}
                          format={"HH:mm:a"}
                        /> */}
                        <input
                          type={"time"}
                          style={{
                            border: "1px solid #d9d9d9",
                            padding: "3px 11px 4px",
                            borderRadius: "2px",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="shipping_label"
                        label="shipping label"
                        rules={[
                          {
                            required: false,
                            message: "Please select shipping label!",
                          },
                        ]}
                      >
                        <Upload
                          {...props}
                          data={{
                            item_id: item_id,
                            shipping_id: shipping_id,
                            doc_type: "label",
                          }}
                        >
                          <Button icon={<UploadOutlined />}>
                            Click to Upload
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Item>
                        <Button type="primary" htmlType="submit" size="middle">
                          Save Shipping label created
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Modal>
            {/* Modal end */}
            <Modal
              visible={isShipmentDelivered}
              title="Update shipment delivered"
              onCancel={() => {
                this.setState({ isShipmentDelivered: false });
              }}
              destroyOnClose={true}
              width={800}
              footer={[
                <Button
                  type=""
                  onClick={() => {
                    this.setState({ isShipmentDelivered: false });
                  }}
                >
                  Cancel
                </Button>,
              ]}
            >
              <div className="form-design">
                <Form
                  layout="vertical"
                  onFinish={this.saveDelivery}
                  scrollToFirstError
                  ref={this.formRefDel}
                >
                  <Row>
                    <Col md={12}>
                      <Form.Item
                        name="delivery_date"
                        label="Delivery date"
                        rules={[
                          {
                            required: true,
                            message: "Please enter delivery date!",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          format="DD-MM-YYYY"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="pod"
                        label="POD"
                        rules={[
                          {
                            required: false,
                            message: "Please select POD!",
                          },
                        ]}
                      >
                        <Upload
                          {...props}
                          data={{
                            item_id: item_id,
                            shipping_id: shipping_id,
                            doc_type: "POD",
                          }}
                        >
                          <Button
                            icon={<UploadOutlined />}
                            style={{ width: "100%" }}
                          >
                            Click to Upload
                          </Button>
                        </Upload>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={12}>
                      <Form.Item>
                        <Button type="primary" htmlType="submit" size="middle">
                          Save shipment delivered
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Modal>
            <Modal
              visible={isUpdateFailedStatus}
              title="Collection Failed"
              onCancel={() => {
                this.setState({
                  isUpdateFailedStatus: false,
                  collection_logs: [],
                });
              }}
              destroyOnClose={true}
              width={"80%"}
              style={{
                top: 20,
              }}
              footer={[
                <Button
                  type=""
                  onClick={() => {
                    this.setState({
                      isUpdateFailedStatus: false,
                      collection_logs: [],
                    });
                  }}
                >
                  Cancel
                </Button>,
              ]}
            >
              <div className="form-design form-design-failed">
                <Form
                  layout="vertical"
                  onFinish={this.saveCollectionFailLogs}
                  scrollToFirstError
                >
                  <Row>
                    <Col md={8}>
                      <Form.Item
                        name="failure_date"
                        label="Collection failure date"
                        rules={[
                          {
                            required: true,
                            message: "Please enter collection failure date!",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          format="DD-MM-YYYY"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={4}>
                      <Form.Item
                        name="failure_time"
                        label="Collection failure time"
                        rules={[
                          {
                            required: true,
                            message: "Please enter collection failure time!",
                          },
                        ]}
                      >
                        <input
                          type={"time"}
                          style={{
                            border: "1px solid #d9d9d9",
                            padding: "3px 11px 4px",
                            borderRadius: "2px",
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col md={8}>
                      <Form.Item
                        name="reschedule_date"
                        label="Reschedule date"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Reschedule date!",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          format="DD-MM-YYYY"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={4}>
                      <Form.Item
                        name="reschedule_time"
                        label="Reschedule time"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Reschedule time!",
                          },
                        ]}
                      >
                        <input
                          type={"time"}
                          style={{
                            border: "1px solid #d9d9d9",
                            padding: "3px 11px 4px",
                            borderRadius: "2px",
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="reason"
                        label="Collection failure reason"
                        rules={[
                          {
                            required: true,
                            message: "Please enter collection failure reason!",
                          },
                        ]}
                      >
                        <TextArea
                          rows={2}
                          placeholder="Collection failure Reason!"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item>
                        <Button
                          type="success"
                          htmlType="submit"
                          size="medium"
                          style={{
                            marginTop: "32px",
                            marginLeft: "20px",
                          }}
                        >
                          Save Collection Failure Reason
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col md={24}>
                      <List
                        itemLayout="horizontal"
                        dataSource={collection_logs}
                        size="small"
                        renderItem={(collection_logs) => (
                          <List.Item>
                            <List.Item.Meta
                              title={
                                <Tag color="lime">
                                  Attemp {collection_logs.attempt}
                                </Tag>
                              }
                              description={
                                <>
                                  <p>
                                    Failure Date & Time :{" "}
                                    <Tag color="red">
                                      {moment(
                                        collection_logs.failure_date +
                                          " " +
                                          collection_logs.failure_time
                                      ).format("DD-MM-YYYY hh:mm a")}
                                    </Tag>
                                  </p>
                                  <p>
                                    Reschedule Date & Time :{" "}
                                    <Tag color="success">
                                      {moment(
                                        collection_logs.reschedule_date +
                                          " " +
                                          collection_logs.reschedule_time
                                      ).format("DD-MM-YYYY hh:mm a")}
                                    </Tag>
                                  </p>
                                  <p>
                                    Reason: <b>{collection_logs.reason}</b>
                                  </p>
                                </>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    </Col>
                  </Row>
                </Form>
              </div>
            </Modal>
            <Modal
              visible={isDeliveryFailedStatus}
              title="Delivery Fail:-"
              onCancel={() => {
                this.setState({
                  isDeliveryFailedStatus: false,
                  delivery_logs: [],
                });
              }}
              destroyOnClose={true}
              width={"80%"}
              style={{
                top: 20,
              }}
              footer={[
                <Button
                  type=""
                  onClick={() => {
                    this.setState({
                      isDeliveryFailedStatus: false,
                      delivery_logs: [],
                    });
                  }}
                >
                  Cancel
                </Button>,
              ]}
            >
              <div className="form-design form-design-failed">
                <Form
                  layout="vertical"
                  onFinish={this.saveDeliveryFailLogs}
                  scrollToFirstError
                >
                  <Row>
                    <Col md={8}>
                      <Form.Item
                        name="failure_date"
                        label="Delivery failure date"
                        rules={[
                          {
                            required: true,
                            message: "Please enter delivery failure date!",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          format="DD-MM-YYYY"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={4}>
                      <Form.Item
                        name="failure_time"
                        label="Delivery failure time"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Delivery failure time!",
                          },
                        ]}
                      >
                        <input
                          type={"time"}
                          style={{
                            border: "1px solid #d9d9d9",
                            padding: "3px 11px 4px",
                            borderRadius: "2px",
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>

                    <Col md={8}>
                      <Form.Item
                        name="reschedule_date"
                        label="Reschedule date"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Reschedule date!",
                          },
                        ]}
                      >
                        <DatePicker
                          style={{ width: "100%" }}
                          format="DD-MM-YYYY"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={4}>
                      <Form.Item
                        name="reschedule_time"
                        label="Reschedule time"
                        rules={[
                          {
                            required: true,
                            message: "Please enter Reschedule time!",
                          },
                        ]}
                      >
                        <input
                          type={"time"}
                          style={{
                            border: "1px solid #d9d9d9",
                            padding: "3px 11px 4px",
                            borderRadius: "2px",
                            width: "100%",
                          }}
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item
                        name="reason"
                        label="Delivery failure reason"
                        rules={[
                          {
                            required: true,
                            message: "Please enter delivery failure reason!",
                          },
                        ]}
                      >
                        <TextArea
                          rows={2}
                          placeholder="Delivery failure Reason!"
                        />
                      </Form.Item>
                    </Col>
                    <Col md={12}>
                      <Form.Item>
                        <Button
                          type="success"
                          htmlType="submit"
                          size="medium"
                          style={{
                            marginTop: "32px",
                            marginLeft: "20px",
                          }}
                        >
                          Save Delivery Failure Reason
                        </Button>
                      </Form.Item>
                    </Col>
                    <Col md={24}>
                      <List
                        itemLayout="horizontal"
                        dataSource={delivery_logs}
                        size="small"
                        renderItem={(delivery_logs) => (
                          <List.Item>
                            <List.Item.Meta
                              title={
                                <Tag color="lime">
                                  Attemp {delivery_logs.attempt}
                                </Tag>
                              }
                              description={
                                <>
                                  <p>
                                    Failure Date & Time :{" "}
                                    <Tag color="red">
                                      {moment(
                                        delivery_logs.failure_date +
                                          " " +
                                          delivery_logs.failure_time
                                      ).format("DD-MM-YYYY hh:mm a")}
                                    </Tag>
                                  </p>
                                  <p>
                                    Reschedule Date & Time :{" "}
                                    <Tag color="success">
                                      {moment(
                                        delivery_logs.reschedule_date +
                                          " " +
                                          delivery_logs.reschedule_time
                                      ).format("DD-MM-YYYY hh:mm a")}
                                    </Tag>
                                  </p>
                                  <p>
                                    Reason: <b>{delivery_logs.reason}</b>
                                  </p>
                                </>
                              }
                            />
                          </List.Item>
                        )}
                      />
                    </Col>
                  </Row>
                </Form>
              </div>
            </Modal>
          </>
        }
      />
    );
  }
}

export default ViewShippingRequest;
