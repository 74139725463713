import React from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import {
  Row,
  Col,
  Table,
  Button,
  Switch,
  notification,
  Modal,
  Select,
} from "antd";
import { withRouter } from "react-router-dom";
import { token } from "../../Helper/Session";
import { UsergroupAddOutlined } from "@ant-design/icons";
import Form from "../../Helper/Form";

class CustomerAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      openAssignUserModal: false,
      customer_account: [],
    };
  }

  goToAddUser = () => {
    this.props.history.push("/add-customer-account");
  };

  formRef = React.createRef();
  componentDidMount() {
    fetch(`${BASE_URL}${API.CUSTOMER_ACCOUNT_LIST}`, {
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            users: json.data,
          });
          notification.success({ message: json.success_msg });
        } else {
          notification.error({ message: json.error_msg });
        }
      });
    fetch(`${BASE_URL}${API.CUSTOMER_USER_LIST}`, {
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            customer_account: json.data,
          });
        }
      });
  }

  handleAssignCustomerAccount = (value) => {
    var assign_data = {
      customer_user_id: value.name,
      user_id: this.state.selectedUserId,
    };
    fetch(`${BASE_URL}${API.ASSIGN_CUSTOMER_ACCOUNT}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify(assign_data),
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          notification.success({ message: json.success_msg, placement: "top" });
          this.setState({ openAssignUserModal: false });
          this.formRef.current.setFieldsValue({
            name: [],
          });
        } else {
          notification.error({ message: json.error_msg, placement: "top" });
        }
      });
  };

  loadAssignUserAccount = (user_id) => {
    fetch(`${BASE_URL}${API.GET_ASSIGN_CUSTOMER_ACCOUNT}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({ user_id: user_id }),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ openAssignUserModal: true });
        if (json.status == true) {
          this.formRef.current.setFieldsValue({
            name: json.data,
          });
        } else {
          this.formRef.current.setFieldsValue({
            name: [],
          });
        }
      });
  };

  render() {
    const { users, openAssignUserModal, customer_account } = this.state;
    // console.log(user);
    const { Option } = Select;
    var data = [];
    const columns = [
      {
        title: "SNo",
        dataIndex: "key",
      },
      {
        title: "Customer Account Name",
        dataIndex: "name",
      },
      {
        title: "Short Name",
        dataIndex: "nickname",
      },
      {
        title: "Status",
        dataIndex: "status",
      },
      {
        title: "Action",
        dataIndex: "action",
      },
    ];
    users.map((usr, i) =>
      data.push({
        key: i + 1,
        name: usr["customer_account_name"],
        nickname: usr["nickname"],
        status: (
          <Switch
            size="default"
            checkedChildren="Active"
            unCheckedChildren="InActive"
            defaultChecked={usr["status"] == 1 ? true : false}
            style={{
              width: "80px",
            }}
          />
        ),
        action: (
          <>
            <Button
              type="primary"
              size="medium"
              style={{ background: "#ffc107", border: "none" }}
              onClick={() =>
                this.props.history.push("edit-customer-account/?user_id=" + usr["id"])
              }
            >
              Edit
            </Button>
            &nbsp;
            <Button
              type="secondary"
              title="Assign Customer User"
              size="medium"
              onClick={() => {
                this.loadAssignUserAccount(usr["id"]);
                this.setState({
                  selectedUserId: usr["id"],
                });
              }}
            >
              <UsergroupAddOutlined />
            </Button>
          </>
        ),
      })
    );

    return (
      <Headers
        bodyContain={
          <>
            <Row>
              <Col offset={20}>
                <Button
                  onClick={this.goToAddUser}
                  type="primary"
                  style={{ marginBottom: "10px" }}
                  shape="round"
                >
                  Add Customer Account
                </Button>
              </Col>
            </Row>
            <div className="site-layout-background">
              <Row>
                <Col md={24}>
                  <Table
                    columns={columns}
                    dataSource={data}
                    size="middle"
                    bordered
                    loading="false"
                  />
                </Col>
              </Row>
            </div>
            <Modal
              title="Assign customer users"
              style={{ top: 20 }}
              visible={openAssignUserModal}
              width={600}
              onCancel={() => {
                this.setState({
                  openAssignUserModal: false,
                });
              }}
              footer={[
                <Button
                  key="cancel"
                  type="default"
                  loading=""
                  onClick={() => this.setState({ openAssignUserModal: false })}
                >
                  Cancel
                </Button>,
              ]}
            >
              <div className="form-design">
                <Form
                  name="assign_customer_account"
                  layout="vertical"
                  onFinish={this.handleAssignCustomerAccount}
                  scrollToFirstError
                  ref={this.formRef}
                >
                  <Row>
                    <Col md={24}>
                      <Form.Item
                        name="name"
                        label="Customer user name"
                        rules={[
                          {
                            required: false,
                            message: "Please select customer user name!",
                          },
                        ]}
                      >
                        <Select
                          allowClear={true}
                          mode="multiple"
                          size="large"
                          // value={selectedValues}
                          autoClearSearchValue={true}
                          filterOption={(input, option) =>
                            option.props.children
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0 ||
                            option.props.value
                              .toLowerCase()
                              .indexOf(input.toLowerCase()) >= 0
                          }
                        >
                          {customer_account.map((ca) => {
                            return (
                              <Option key={ca.id} value={ca.id}>
                                {ca.name}
                              </Option>
                            );
                          })}
                        </Select>
                      </Form.Item>
                    </Col>
                  </Row>
                  <Row>
                    <Col md={24}>
                      <Form.Item>
                        <Button
                          type="primary"
                          htmlType="submit"
                          size="large"
                          loading={false}
                        >
                          Save assign users
                        </Button>
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>
            </Modal>
          </>
        }
      />
    );
  }
}
export default withRouter(CustomerAccount);
