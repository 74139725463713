// import React from "react";
import React, { useState } from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import { Row, Col, Table, Button, Switch, notification } from "antd";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { withRouter } from "react-router-dom";
import { token } from "../../Helper/Session";

class Users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      user_role: this.props.user_role
    };
    if(this.props.user_role != this.state.user_role){
      window.location.reload();
    }
  }

  goToAddUser = () => {
    this.props.history.push("/add-user");
  };

  componentDidMount() {
    var user_role = this.state.user_role;
    if (typeof user_role === "undefined" || user_role === null) {
      user_role = 0;
    }
    fetch(`${BASE_URL}${API.USERLISTING}?user_role=${user_role}`, {
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status == true) {
          this.setState({
            users: json.data,
          });
          notification.success({message:json.success_msg});
        } else {
          notification.error({message:json.error_msg});
        }
      });
  }

  handleStatus = (user_id, v) => {
    if (v === true) {
      fetch(`${BASE_URL}${API.UPDATE_USER_STATUS}`, {
        method: "POST",
        headers: {
          Accept: "application/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ status: 1, user_id: user_id }),
      });
    } else if (v === false) {
      fetch(`${BASE_URL}${API.UPDATE_USER_STATUS}`, {
        method: "POST",
        headers: {
          Accept: "application/form-data",
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
        body: JSON.stringify({ status: 0, user_id: user_id }),
      });
    }
  };

  render() {
    const { users } = this.state;
    // console.log(user);
    var data = [];
    const columns = [
      {
        title: "SNo",
        dataIndex: "key",
      },
      {
        title: "Name",
        dataIndex: "name",
      },
      {
        title: "Email",
        dataIndex: "email",
      },
      {
        title: "Contact",
        dataIndex: "mobile",
      },
      {
        title: "Status",
        dataIndex: "status",
      },
      {
        title: "Action",
        dataIndex: "action",
      },
    ];
    users.map((usr, i) =>
      data.push({
        key: i + 1,
        name: usr["first_name"] + " " + usr["last_name"],
        email: usr["email"],
        mobile: usr["mobile"] != "" ? usr["mobile"] : "",
        status: (
          <Switch
            size="default"
            checkedChildren= "Active"
            unCheckedChildren="InActive"
            defaultChecked={usr['status'] == 1 ? true : false}
            onChange={this.handleStatus.bind(this, usr["id"])}
            style={{
              width:"80px"
            }}
          />
        ),
        action: (
          <Button
            type="primary"
            size="medium"
            style={{ background: "#ffc107", border: "none" }}
          >
            Edit
          </Button>
        ),
      })
    );

    return (
      <Headers
        bodyContain={
          <>
            <Row>
              <Col offset={22} md={2}>
                <Button
                  onClick={this.goToAddUser.bind(this)}
                  type="primary"
                  style={{ marginBottom: "10px" }}
                  shape="round"
                >
                  Add User
                </Button>
              </Col>
            </Row>
            <div className="site-layout-background">
              <Row>
                <Col md={24}>
                  <Table
                    columns={columns}
                    dataSource={data}
                    size="middle"
                    bordered
                    loading="false"
                  />
                </Col>
              </Row>
            </div>
          </>
        }
      />
    );
  }
}
export default withRouter(Users);
