import React from "react";
import Headers from "../component/Headers";
import { BASE_URL, API } from "../../Endpoint";
import {
  Row,
  Col,
  Table,
  Button,
  notification,
  Spin,
  Card,
  Input,
  message,
  DatePicker,
  Select,
  Tag,
} from "antd";
import { withRouter } from "react-router-dom";
import { token } from "../../Helper/Session";
import { ExportOutlined } from "@ant-design/icons";

import Form from "../../Helper/Form";

import moment from "moment";

const { Search } = Input;
class ShippingRequestList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shipping_request: [],
      isLoadingRequestList: true,
      isSearching: false,
      track_data: [],
      customer_account: [],
    };
  }

  gotToAddSR = () => {
    this.props.history.push("/add-shipping-request");
  };
  goToGenerateReport = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const customer_account = queryParams.get("customer_account");
    const status = queryParams.get("status");
    const from_date = queryParams.get("from_date");
    const to_date = queryParams.get("to_date");
    let from_date_ = from_date ? moment(from_date).format("YYYY-MM-DD") : "";
    let to_date_ = to_date ? moment(to_date).format("YYYY-MM-DD") : "";
    let status_ = status ? status : "";
    window.location.href =
      `${BASE_URL}${API.GENERATE_REPORT}` +
      "?status=" +
      status_ +
      "&from_date=" +
      from_date_ +
      "&to_date=" +
      to_date_ +
      "&customer_account=" +
      customer_account;
  };
  formRef = React.createRef();

  componentDidMount() {
    const queryParams = new URLSearchParams(window.location.search);
    const status = queryParams.get("status");
    const from_date = queryParams.get("from_date");
    const to_date = queryParams.get("to_date");
    const customer_account = queryParams.get("customer_account");
    this.formRef.current.setFieldsValue({
      status: status,
      from_date: from_date ? moment(from_date) : "",
      to_date: to_date ? moment(to_date) : "",
      customer_account: customer_account,
    });
    this.setState({ isLoadingRequestList: true });
    fetch(`${BASE_URL}${API.SHIPPING_REQUEST_LIST}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        status: status ? status : "",
        from_date: from_date ? from_date : "",
        to_date: to_date ? to_date : "",
        customer_account: customer_account ? customer_account : "",
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isLoadingRequestList: false });
        if (json.status == true) {
          this.setState({
            shipping_request: json.data,
          });
          notification.success({
            message: json.success_msg,
          });
        } else {
          notification.error({
            message: json.error_msg,
          });
        }
      });

    fetch(`${BASE_URL}${API.CUSTOMER_ACCOUNT_LIST}`, {
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.status === true) {
          this.setState({
            customer_account: json.data,
          });
        } else {
          notification.error({ message: json.error_msg });
        }
      });
  }

  handleTracking = (value) => {
    this.setState({ isSearching: true });
    fetch(`${BASE_URL}${API.SHIPMENT_TRACKING}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        tracking_id: value,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isSearching: false });
        if (json.status == true) {
          this.setState({
            track_data: json.data,
          });
          this.props.history.push("/shipping-request/view/" + json.data.id);
        } else {
          message.error({
            content: json.error_msg,
            duration: 2,
          });
        }
      });
  };

  onFinish = (value) => {
    let from_date = value.from_date
      ? moment(value.from_date).format("YYYY-MM-DD")
      : "";
    let to_date = value.to_date
      ? moment(value.to_date).format("YYYY-MM-DD")
      : "";
    let status = value.status ? value.status : "";
    let customer_account = value.customer_account ? value.customer_account : "";
    window.location.href =
      "/shipping-request-list/?status=" +
      status +
      "&from_date=" +
      from_date +
      "&to_date=" +
      to_date +
      "&customer_account=" +
      customer_account;
  };

  render() {
    const {
      shipping_request,
      isLoadingRequestList,
      isSearching,
      customer_account,
    } = this.state;

    var data = [];
    const columns = [
      {
        title: "SNo",
        dataIndex: "key",
      },
      {
        title: "Request Id",
        dataIndex: "request_id",
      },
      {
        title: "Service Now Id",
        dataIndex: "servicenow_id",
      },
      {
        title: "Shipment Id",
        dataIndex: "shipment_id",
      },
      {
        title: "SO number",
        dataIndex: "so_number",
      },
      {
        title: "Request created by",
        dataIndex: "customer_name",
      },
      {
        title: "Ship To Address",
        dataIndex: "ship_to",
      },
      {
        title: "Ship From Address",
        dataIndex: "ship_from",
      },
      {
        title: "Status",
        dataIndex: "status",
      },
      {
        title: "View",
        dataIndex: "action",
      },
    ];
    shipping_request.map((sr, i) =>
      data.push({
        key: i + 1,
        request_id: sr["request_id"],
        servicenow_id: sr["servicenow_id"],
        shipment_id:
          sr["request_id"] +
          (sr["shipment_id"] ? "-" + sr["shipment_id"] : "") +
          (sr["is_reverse"] == "yes" ? "-REV" : ""),
        so_number: sr["so_number"],
        ship_from:
          sr["is_reverse"] == "yes"
            ? sr["to_street"] +
              ", " +
              sr["to_city"] +
              ", " +
              sr["to_state"] +
              ", " +
              sr["to_country"] +
              " " +
              (sr["to_postal"] ? " - " + sr["to_postal"] : "")
            : sr.from_street +
              ", " +
              sr.from_city +
              ", " +
              sr.from_state +
              ", " +
              sr.from_country +
              " " +
              (sr.from_postal ? " - " + sr.from_postal : ""),
        ship_to:
          sr["is_reverse"] == "yes"
            ? sr.from_street +
              ", " +
              sr.from_city +
              ", " +
              sr.from_state +
              ", " +
              sr.from_country +
              " " +
              (sr.from_postal ? " - " + sr.from_postal : "")
            : sr["to_street"] +
              ", " +
              sr["to_city"] +
              ", " +
              sr["to_state"] +
              ", " +
              sr["to_country"] +
              " " +
              (sr["to_postal"] ? " - " + sr["to_postal"] : ""),
        customer_name: sr["customer_name"],
        status: (
          <>
            {sr.shipment_status == 1 && (
              <Tag color="#002140">Request placed</Tag>
            )}
            {sr.shipment_status == 2 && (
              <Tag color="#019b04">Shipping Label created</Tag>
            )}
            {sr.shipment_status == 3 && (
              <Tag color="#ff4d4f">Collection failed</Tag>
            )}
            {sr.shipment_status == 4 && <Tag color="#e2ca10">In-Transit</Tag>}
            {sr.shipment_status == 5 && (
              <Tag color="#ff4d4f">Delivery failed</Tag>
            )}
            {sr.shipment_status == 6 && <Tag color="#1b74c6">Delivered</Tag>}
          </>
        ),
        action: (
          <Button
            type="secondary"
            size="small"
            onClick={() => {
              this.props.history.push(
                "/shipping-request/view/" + sr["ref_shipment_id"]
              );
            }}
          >
            View
          </Button>
        ),
      })
    );

    return (
      <Headers
        bodyContain={
          <>
            <Row>
              <Col md={24} sm={24} xs={24}>
                <Card style={{ marginBottom: "5px" }}>
                  <Row style={{ justifyContent: "space-between" }}>
                    {/* <Col md={3}>
                      <h3>Shipping Request</h3>
                    </Col> */}
                    <Col md={16} className="p5">
                      <Search
                        type="secondary"
                        placeholder="Enter shipment Id"
                        enterButton="Search"
                        size="medium"
                        loading={isSearching}
                        onSearch={this.handleTracking}
                      />
                      <span>
                        Please enter shipment id to search the shipment.
                      </span>
                    </Col>
                    <Col>
                      <Button
                        onClick={this.gotToAddSR} ///{this.gotToAddSR}
                        type="warning"
                        style={{ marginBottom: "10px" }}
                        shape="round"
                        // size="large"
                      >
                        Add Shipping Request
                      </Button>
                    </Col>
                  </Row>
                </Card>
              </Col>
            </Row>
            <h3 className="title">Shipping Request List</h3>
            <div className="site-layout-background">
              <Form
                name="search_filter"
                layout="horizontal"
                onFinish={this.onFinish}
                scrollToFirstError
                ref={this.formRef}
              >
                <Row>
                  <Col className="p5 filter">
                    <span>Customer Account</span>
                    <Form.Item name="customer_account">
                      <Select
                        style={{ width: "100%" }}
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .includes(input.toLowerCase())
                        }
                        placeholder="Select customer account"
                      >
                        <option value="">Select customer account</option>
                        {customer_account.map((ca, i) => {
                          return <option value={ca.id}>{ca.nickname}</option>;
                        })}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="p5 filter">
                    <span>Status</span>
                    <Form.Item name="status">
                      <Select placeholder="Select request status">
                        <option value="">Select...</option>
                        <option value="1">Request Placed</option>
                        <option value="2">Label created</option>
                        <option value="4">In-Transit</option>
                        <option value="6">Delivered</option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="p5 filter">
                    <span> From Date</span>
                    <Form.Item name="from_date">
                      <DatePicker format="DD-MM-YYYY" />
                    </Form.Item>
                  </Col>
                  <Col className="p5 filter">
                    <span>To Date</span>
                    <Form.Item name="to_date">
                      <DatePicker format="DD-MM-YYYY" />
                    </Form.Item>
                  </Col>
                  <Col className="p5 filter">
                    <Button
                      htmlType="submit"
                      type="primary"
                      style={{ marginTop: "20px" }}
                    >
                      Search
                    </Button>
                    <Button>Reset</Button>
                  </Col>
                  <Col className="p5 filter">
                    <Button
                      onClick={this.goToGenerateReport}
                      type="success"
                      style={{ marginTop: "20px" }}
                      icon={<ExportOutlined />}
                    >
                      Export
                    </Button>
                  </Col>
                </Row>
              </Form>
              <Spin spinning={isLoadingRequestList}>
                <Row>
                  <Col md={24}>
                    <Table
                      columns={columns}
                      dataSource={data}
                      size="middle"
                      bordered
                      loading="false"
                    />
                  </Col>
                </Row>
              </Spin>
            </div>
          </>
        }
      />
    );
  }
}
export default withRouter(ShippingRequestList);
