import React from "react";
import Headers from "./component/Headers";
import { Card, Button, Input, message } from "antd";
import { UnorderedListOutlined, FolderAddOutlined } from "@ant-design/icons";
import { Col, Row, Steps, Divider, Tag } from "antd";
import { API, BASE_URL } from "../Endpoint";
import { token } from "../Helper/Session";
import { withRouter } from "react-router-dom";
const { Step } = Steps;
const { Search } = Input;
class Home extends React.Component {
  state = {
    loading: false,
    stats: [],
    placed: 0,
    create: 0,
    transit: 0,
    delivery: 0,
    track_data: [],
    request_shipping_status: 0,
    isSearching: false,
    stepperDisplay: "hide_box",
  };

  handleTracking = (value) => {
    this.setState({ isSearching: true, stepperDisplay: "hide_box" });
    fetch(`${BASE_URL}${API.SHIPMENT_TRACKING}`, {
      method: "POST",
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
      body: JSON.stringify({
        tracking_id: value,
      }),
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ isSearching: false });
        if (json.status == true) {
          this.setState({
            track_data: json.data,
            request_shipping_status: json.data.shipping_status,
            stepperDisplay: "show_box",
          });
          // this.props.history.push("/shipping-request/view/" + json.data.id);
        } else {
          message.error({
            content: json.error_msg,
            duration: 2,
          });
        }
      });
  };

  componentDidMount() {
    this.setState({ loading: true });
    fetch(`${BASE_URL}${API.DASHBOARD}`, {
      headers: {
        Accept: "application/form-data",
        "Content-Type": "application/json",
        Authorization: "Bearer " + token,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({ loading: false });
        if (json.status === true) {
          this.setState({ stats: json.data });
        }
      });
  }
  render() {
    const {
      loading,
      stats,
      isSearching,
      stepperDisplay,
      request_shipping_status,
      track_data
    } = this.state;
    const { description } = "This is a description.";
    stats.map((st, i) => {
      if (st.status == 1) {
        this.state.placed = st.count;
      } else if (st.status == 2) {
        this.state.create = st.count;
      } else if (st.status == 4) {
        this.state.transit = st.count;
      } else if (st.status == 6) {
        this.state.delivery = st.count;
      }
    });
    return (
      <Headers
        bodyContain={
          <>
            <Row>
              <Col md={16}>
                <Row>
                  <Col sm={12} xs={24}>
                    <Card
                      className="card"
                      onClick={
                        () =>
                          this.props.history.push(
                            "/shipping-request-list/?status=1"
                          ) /* for parameter is to check it is in request placed*/
                      }
                      style={{ background: "#fffff" }}
                      loading={loading}
                    >
                      <Row>
                        <Col>
                          <h5 className="font-weight-bold text-primary">
                            Request Placed
                          </h5>
                          <p>{this.state.placed}</p>
                        </Col>
                        {/* <Col xs={4}>
                      <UnorderedListOutlined className="icon_size" style={{background:"#f0f2f5", padding:"10px", borderRadius:"10px"}} />
                    </Col> */}
                      </Row>
                    </Card>
                  </Col>
                  <Col sm={12} xs={24}>
                    <Card
                      className="card"
                      loading={loading}
                      onClick={() =>
                        this.props.history.push(
                          "/shipping-request-list/?status=2"
                        )
                      }
                    >
                      <Row>
                        <Col xs={20}>
                          <h5 className="font-weight-bold text-success">
                            Label Created
                          </h5>
                          <p>{this.state.create}</p>
                        </Col>
                        {/* <Col xs={4}>
                      <UnorderedListOutlined className="icon_size" />
                    </Col> */}
                      </Row>
                    </Card>
                  </Col>
                  <Col sm={12} xs={24}>
                    <Card
                      className="card"
                      loading={loading}
                      onClick={() =>
                        this.props.history.push(
                          "/shipping-request-list/?status=4"
                        )
                      }
                    >
                      <Row>
                        <Col xs={20}>
                          <h5 className="font-weight-bold text-warning">
                            In-Transit
                          </h5>
                          <p>{this.state.transit}</p>
                        </Col>
                        {/* <Col xs={4}>
                      <UnorderedListOutlined className="icon_size" />
                    </Col> */}
                      </Row>
                    </Card>
                  </Col>
                  <Col sm={12} xs={24}>
                    <Card
                      className="card"
                      loading={loading}
                      onClick={() =>
                        this.props.history.push(
                          "/shipping-request-list/?status=6"
                        )
                      }
                    >
                      <Row>
                        <Col xs={20}>
                          <h5 className="font-weight-bold text-info">
                            Delivered
                          </h5>
                          <p>{this.state.delivery}</p>
                        </Col>
                        {/* <Col xs={4}>
                      <UnorderedListOutlined className="icon_size" />
                    </Col> */}
                      </Row>
                    </Card>
                  </Col>
                  <Col sm={24} xs={24}>
                    <div class="">
                      <Button
                        type="secondary"
                        onClick={() =>
                          this.props.history.push("/add-shipping-request")
                        }
                        className="add_shipping_btn"
                      >
                        Add Shipping Request
                        <br />
                        <FolderAddOutlined style={{ fontSize: "26px" }} />
                      </Button>
                    </div>
                  </Col>
                </Row>
              </Col>
              {/* tracking info */}
              <Col md={8} sm={24} xs={24}>
                <Col sm={24} xs={24}>
                  <Card className="card">
                    <Col xs={24}>
                      <span>
                        Please enter shipment id to search the shipment.
                      </span>
                      <Search
                        type="secondary"
                        placeholder="Enter shipment Id"
                        enterButton="Search"
                        size="medium"
                        loading={isSearching}
                        onSearch={this.handleTracking}
                      />
                      <Divider />
                      <div className={stepperDisplay}>
                        <Row className="shipping_status">
                          <Col>
                            {request_shipping_status == 1 && (
                              <Tag color="#002140">Request placed</Tag>
                            )}
                            {request_shipping_status == 2 && (
                              <Tag color="#019b04">Shipping Label created</Tag>
                            )}
                            {request_shipping_status == 4 && (
                              <Tag color="#e2ca10">In-Transit</Tag>
                            )}
                            {request_shipping_status == 6 && (
                              <Tag color="#1b74c6">Delivered</Tag>
                            )}
                            {request_shipping_status == 3 && (
                              <Tag color="#ff7875">Collection Failed</Tag>
                            )}
                            {request_shipping_status == 5 && (
                              <Tag color="#ff7875">Delivery Failed</Tag>
                            )}
                          </Col>
                          <Col>
                            <Tag color="grey" style={{"cursor": "pointer"}} onClick={() => this.props.history.push("/shipping-request/view/" + track_data.id)}>Shipping Request Id : {track_data.request_id}</Tag>
                          </Col>
                        </Row>
                        <Steps
                          direction="vertical"
                          size="small"
                          current={parseInt(request_shipping_status) - 1}
                          status={
                            parseInt(request_shipping_status) == 3 ||
                            parseInt(request_shipping_status) == 5
                              ? "error"
                              : "process"
                          }
                        >
                          <Step
                            title="Request Placed"
                            description="This mean shipping for this request is placed."
                          />
                          <Step
                            title="Shipping label created"
                            description="This mean user label uploaded for this shipping request."
                          />
                          <Step title="Pickup" description="" />
                          <Step
                            title="In-Transit"
                            description="Your shipping request is In-Transit."
                          />
                          <Step title="Delivery" description="" />
                          <Step
                            title="Delivered"
                            description="Shipment is delivered."
                          />
                        </Steps>
                      </div>
                    </Col>
                  </Card>
                </Col>
              </Col>
              {/* end tracking info */}
            </Row>
          </>
        }
      />
    );
  }
}

export default withRouter(Home);
